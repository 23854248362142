<template>
  <el-card shadow="never">
    <div slot="header" class="clearfix">
      <el-form
          id="change"
          status-icon
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
      >撰稿人名称：
        <el-input
            style="width: 200px; margin-right: 10px"
            class="search"
            v-model="asearch1"
            prefix-icon="el-icon-search"
            clearable
            placeholder="请输入用户名称"
        />
        撰稿人状态：
        <el-select clearable v-model="value" placeholder="请选择"  style="margin-right: 10px">
          <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.label"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="getUserList()" style="background: #1d90ff; border:none">查询</el-button>
        <el-button type="success" @click="addArticle()" style="background: #1d90ff; border:none">添加用户</el-button>
      </el-form>
    </div>
    <el-table :data="tableData" stripe style="width: 100%;margin-top:10px" border>
      <el-table-column prop="id" label="撰稿人编号" width="120" align="center">
      </el-table-column>
      <el-table-column prop="nickName" label="撰稿人名称"  width="150" align="center">
      </el-table-column>
      <el-table-column prop="userTypeName" label="用户类型" align="center" width="150"></el-table-column>
      <el-table-column label="状态" width="150" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status == -99" style="color: red">已锁定</span>
          <span v-if="scope.row.status == 0" style="color: green">启用</span>
        </template>
      </el-table-column>
      <el-table-column label="状态修改"  width="120" align="center" v-if="userInfo.userType==-99">
        <!-- eslint-disable-next-line -->
        <template slot-scope="scope">
          <el-dropdown trigger="click">
              <span class="el-dropdown-link" style="color:orange;">
                状态修改<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="adminUpdateUserStatus(scope.row.id,0)" icon="el-icon-unlock"
                                style="color:green;">启用
              </el-dropdown-item>
              <el-dropdown-item @click.native="adminUpdateUserStatus(scope.row.id,-99)" icon="el-icon-lock"
                                style="color:red;">锁定
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <!-- eslint-disable-next-line -->
      </el-table-column>
      <el-table-column prop="updatedTime" label="更新时间" width="200" align="center"></el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button type="success" style="background: #1d90ff; border:none"  size="mini" @click="editArticle(scope.row.id)"
          >编辑
          </el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center;margin-top: 20px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 50, 60]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total=pagetotal>
      </el-pagination>
    </div>
  </el-card>
</template>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<script>
import Cookies from "js-cookie";

export default {
  methods: {
    adminUpdateUserStatus(id, status) {
      this.$api.post(
          "/user/admin/updateUserStatus",
          null,
          {
            id: id,
            status: status
          },
          (successRes) => {
            console.log(successRes);
            if (successRes.status == 200) {
              this.$message('操作成功');
              this.getUserList();
            } else {
              this.$message(successRes.message);
            }
          },
          (failureRes) => {
            this.$message('操作失败');
          }
      );
    },
    getUserInfoByUserName() {
      const username = Cookies.get("username");
      this.$api.get(
          "/user/getUserInfoByUserName",
          null,
          {
            username: username
          },
          (successRes) => {
            console.log(successRes);
            this.userInfo = successRes.data;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    getUserList() {
      this.$api.get(
          "/user/list",
          null,
          {
            nickName: this.asearch1,
            status: this.value,
          },
          (successRes) => { 
            var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
              exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
              exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].updatedTime = exf[i].updatedTime.substring(0,10);
            }
            this.tableData = exf;
            this.pagetotal =  successRes.data.total;
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
    addArticle() {
      this.$router.push({name: "nameAdd"});
    },
    editArticle(id) {
      // {name: "nameedit", params: {id: id}}
      this.$router.push({ path: 'nameedit',query: {id: id}});

    },
    handleSizeChange(val) {
       this.spagetotal=val;
         this.$api.get(
          "/user/list",
          null,
          {
            page:this.nowpagetotal,
          record:this.spagetotal,
          nickName: this.asearch1,
            status: this.value,
          },
          (successRes) => {
            this.pagetotal =  successRes.data.total;
            var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
              exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
              exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].updatedTime = exf[i].updatedTime.substring(0,10);
            }
            this.tableData = exf;
            console.log(this.pagetotal);

          },
          (failureRes) => {
            console.log(failureRes);
          }
      );

        console.log(val);
      },
      handleCurrentChange(val) {
        this.nowpagetotal=val;
        this.$api.get(
          "/user/list",
          null,
          {
          page:this.nowpagetotal,
          record:this.spagetotal,
          nickName: this.asearch1,
            status: this.value,
          },
          (successRes) => {
            this.pagetotal =  successRes.data.total;
            var exf = successRes.data.rows;
            for (var i = 0; i< exf.length;i++){
              exf[i].updatedTime = exf[i].updatedTime.substr(0, 19);
              exf[i].updatedTime = new Date(+new Date(exf[i].updatedTime) + 16 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') ;
              exf[i].updatedTime = exf[i].updatedTime.substring(0,10);
            }
            this.tableData = exf;
            console.log(this.pagetotal);

          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
      }
  },

  data() {
    return {
      currentPage4: 1,
      options: [
        {
          id: 0,
          value: "选项1",
          label: "启用",
        },
        {
          id: -99,
          value: "选项2",
          label: "已锁定",
        },
        
      ],
      value:'',
      tableData: [],
      asearch1: "",
      asearch2: "",
      asearchdata: {},
      userInfo: {},
      pagetotal:0,
      spagetotal:'10',
      nowpagetotal:'1',
      
    
    };
  },
  mounted() {
    this.getUserList();
    this.getUserInfoByUserName();
  },
};
</script>